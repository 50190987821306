
@media (max-width: 1200px) {
	.navbar.d-lg-block {
		display:none !important;
	}
	.navbar.d-lg-none {
		display:flex !important;
	}
	.navbar {
		
		.navbar-brand {
			width:100%;
			text-align:center;
		}
		
		.nav-link {
			height:auto !important;
		}
		
		.navbar-nav .dropdown-menu li {
			display: block;
			width: 100%;
		}
		
		.btn-contact {
			margin-left:0 !important;
			width:fit-content !important;
		}
		
	
	}
	
	.navbar.on:before {
		display: none;
	}
	
	.dropdown-menu {
		position: inherit !important;
		top: inherit !important;
		left: inherit !important;
		display:none !important;
		visibility:visible;
		padding:20px 40px !important;
		
		* {
			padding:0;
		}
		
		li {
			margin:5px 0;
		}
		
	}
	.dropdown-menu.show {
		display:block !important;
	}
}
@media (max-width: 768px) {

	* {
		word-break: break-word;
	}
	
	.col-24 {
		padding-left:15px;
		padding-right:15px;
	}
	
	.items {
		padding-left:0;
		padding-right:0;
	}
	
	.page-template-default .navbar {
		border:none;
	}
	
	
	.carousel,
	#carousel,
	.carousel-inner,
	.carousel-item {
		height:auto !important;
	}
	
	footer .cta-contact {
		height:auto;
		padding-top:20px;
		padding-bottom:20px;
	}
	
	
	
	.title {
		padding-left:20px;
		padding-right:20px;
	}
	.title.actus {
		padding-bottom:0;
	}
	
	.lien_tous {
		display:block;
		margin-bottom:20px;
	}
	
	.page-header-2,
	.page-header .chapeau {
		padding: 20px;
	}
	
	#rejoindre a {
		margin-top:10px;
	}
	
	#presence {
		margin-top: 20px;
	}
	
	.actus-page {
		margin-top: 20px;
	}
	
	.block-texte {
	
		padding:20px;	
		
		.padd-2 {
			padding-left: 0;
			padding-right: 0;
		}
		
	}
	
	.page-header .block-beige p {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	
	.block-texte.parle-de-nous {
		padding-top:50px;
		padding-bottom:50px;

		div {
			background-position: 5% 45%;
		}
	}
	
	.h-300,
	.h-400,
	.h-500 {
		height:auto;
		padding-top:20px;
		padding-bottom:20px;
	}
	
	.block-img {
		height:250px;
	}
	
	.v-align,
	.v-align > div {
		/*display:block;*/
	}
	
	.v-align {
		padding-top:0;
		padding-bottom:0;
	}
	
	.nav-item-interne {
		
		display: block;
		
		li {
			width:100% !important;
			display:block !important;
			border:none !important;
			padding: 5px 0 !important;
			
			a {
				padding:0;
				text-align:left;
			}
		}
	}
	
	.realisations {
    	height: auto;
	}
	
	.product-list .product .texte {
		width:100%;
		left:0;
	}
	.filtres {
		.filtre {
		
			margin-top:20px;

			.dropdown-choices {
				display:block;
				position:inherit;
				top:0;
				border-bottom:none;
			}
		}
		.col-md-3 input {
			margin-top:20px;
		}
	}
	
	.actus-page > div:before {
		display:none;
	}
	
	.actu {
		margin-bottom:55px;
	}
	.actu:last-child {
		margin-bottom:0;
	}
	
	.adresse {
		margin-top:20px;
	}
	.filtres button {
		color:$main !important;
		font-weight:500 !important;
	}
	
	.engagements {
	
		.nav-item-interne {
			li {
				text-decoration:underline;
			}
		}
	}
	
}