/* ANIMATION */

.from_left {
    opacity:0;
    transition: 0.5s;
    left:-20px;
}
.from_bottom {
    opacity:0;
	transition: 0.5s;
	top:15px;
	position:relative;
}

.appear {
    opacity:1;
    left:0;
    top:0;
}

.blockheader {
    position:relative;
}

.animation {
}

.img-effect {
    width:300px;
    height:300px;
    position:absolute; 
    transform: translate(-50%,-50%);
    pointer-events: none;
}

.img-effect img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;  
}