html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
	scroll-behavior: smooth;
}

*,
*:after,
*:before {
	margin: 0;
	padding: 0;
	background-size:cover;
	background-repeat:no-repeat;
	outline: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.vert {
	color:$vert;
}
.vert-2 {
	color:$vert-2;
}
.vert-3 {
	color:$vert-3;
}
.vert-4 {
	color:$vert-4;
}
.vert-fonce {
	color:$vert-fonce;
}
.violet {
	color:$violet;
}
.beige {
	color:$beige;
}
.beige-fonce {
	color:$beige-fonce;
}
.orange {
	color:$orange;
}
.grey {
	color:$grey;
}

body{
    color:$main;
	font-family: 'Montserrat', sans-serif;
	font-size: $default-font-size;
    line-height: $default-line-height;
    font-weight: $default-font-weight;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow-x: hidden;
	scroll-behavior: smooth;
}

.font-2 {
	font-family: 'Professor', sans-serif;
}

a, a:hover {
	color:$main;
	text-decoration:none;
}
a:hover {
	color:$vert-4 !important;
}
.site-content {
	
	padding-top:108px;
	padding-bottom:40px;
	
	img {
		width:100% !important;
		max-width:100% !important;
	}
}
img {
	max-width:100% !important;
}
ul.texte {
	list-style: none;
	padding:0 !important;
	margin:0 !important;
	
	li {
		margin-left:30px;
		padding-left:5px;
		position:relative;
		margin-bottom:10px;
	}
	
	li::before {
		position:absolute;
		left:-18px;
		top: -4px;
		font-size:46px;
		content: "•";
		color: $vert;
	}
}


.gl-0 {
	padding-left:0 !important;
}
.gr-0 {
	padding-right:0 !important;
}

.page-template-contact .menu-container,
.page-template-default .menu-container {
	
	border-bottom: solid 1px #ccc;
}

.menu-container {
	position:fixed;
	z-index:999;
	margin:0 auto;
	width:100%;
	background-color:white;
}

.navbar {
	
	/*display:block;*/
	
	/*.navbar-brand {
		float:left;
		margin-top:20px;
	}*/
	
	.navbar-nav {
		
		margin-left:10px;
		/*margin-top:40px;*/
		
		.nav-link {
			color:$main;
			font-weight:500;
			/*height:74px;*/
		}
		
		/*.nav-link.show {
			height:190px;
		}*/
		
		.current-page-parent .nav-link,
		.current_page_item:not(.btn-contact) .nav-link,
		li:not(.btn-contact) .nav-link:hover {
			color:$vert-4 !important;
			/*text-decoration-color:$vert-4 !important;
			text-decoration:underline !important;
			text-decoration-thickness:2px !important;*/
		}
		.btn-contact {
			
			margin-left:20px;
			
			a {
				height:inherit;
				color:$vert-2 !important;
				border:solid 1px $vert-4;
				border-radius:20px;
				padding:8px 20px !important;
			
			}
			a:hover {
				color:white !important;
				background-color:$vert-4;
			}
			
		}
		
		.dropdown-toggle:after {
   			content: '' !important;
			width:20px;
			height:20px;
			background:url('img/Chevron_Bas.svg') no-repeat;
			border:none;
			position: absolute;
			top: 14px;
		}
		.dropdown-toggle i {
			color:$vert;
		}
		
		.dropdown-menu {
			
			border-top: solid 1px #ccc !important;
			position: fixed;
			top: 106px;
			left: 0;
			width:100%;
			border-radius:0;
			border:none;
			padding:20px;
			transition: 0.2s;
			opacity:0 !important;
			visibility: hidden;
			display:block;
			
			li {
				
				display:inline-block;
				width:49%;
				
				a, a:hover {
					background-color:transparent;
					font-size:17px;
					font-weight:500;
					padding: 0 0;
				}
				
			}
			
			li.return {
				display:block;
			}
		}
		.dropdown-menu.show {
			opacity:1 !important;
			visibility: visible;
		}
		.dropdown {
			padding-right:15px;
		}
		/*.dropdown-menu[data-bs-popper] {
			top: 90px !important;
			margin-top:0;
		}*/
		
		.dropdown-item.active, .dropdown-item:active {
			color: $main;
		}
		
		
	}
	
	.navbar-toggler {
		position:absolute;
		right:10px;
		top:10px;
	}
	
}
.navbar.d-lg-block {
	display:flex !important;
}



.lien_tous {
	
	font-size:16px;
	text-decoration:none;
	
	i {
		width: 36px;
		height: 36px;
		margin-left:6px;
		background-image:url('img/Bnt_Vert_Off.svg');
		display:inline-block;
		top: 12px;
    	position: relative;
	}
	i.beige-fonce {
		color:$beige-fonce-2;
		background-image:url('img/Bnt_Beige_Off.svg');
	}
}
.lien_tous:hover {
	
	font-size:16px;
	text-decoration:none;
	
	i {
		background-image:url('img/Bnt_Vert_On.svg');
	}
	i.beige-fonce {
		background-image:url('img/Bnt_Beige_On.svg');
	}
}

.lien_tous.beige-fonce-2 {
	color:$main !important;
}
.lien_tous.beige-fonce-2:hover {
	color:$beige-fonce-2 !important;
}

.see_more {
	
	font-size:16px;
	text-decoration:none;
	
	i {
		color:$vert;
		border:solid 2px $vert;
		border-radius:30px;
		width: 28px;
		height: 28px;
		font-size: 16px;
		padding: 4px 4px 4px 5px;
		margin-left:6px;
	}
	i.beige-fonce {
		color:$beige-fonce-2;
		border:solid 2px $beige-fonce-2;
	}
}

footer {
	
	.cta-contact {
		height:160px;
		background-color:$beige;
		font-size:28px;
		font-weight:700;
		line-height:2.5rem;
		
		> div:first-child {
		}
		
		.email,
		.tel {
			
			color:$main;
			display:inline-block;
			
			img {
				width:50px !important;
			}
			
			a,
			a:hover {
				color:$main !important;
			}
		}
		
		.email {
			margin-left:6px;
			margin-top:10px;
		}
	}
	 ul {
		margin:0;
		padding:0;
		list-style:none;
	}
	#menu-menu-footer-2 {
		margin-top:27px;
	}
	.liens {
		padding-top:60px;
		padding-bottom:60px;
		font-size:16px;
		line-height:1.8rem;
		
		.copyright {
			margin-top:25px;
			display:block;
			font-size:12px;
		}
		
		h2 {
			font-size:16px;
			font-weight:bold;
		}
		a {
			font-size:16px;
		}
		
	}
	.rs {
		ul {

			text-align:left;

			li {
				text-align:left;
				display:inline-block;
				margin-right:20px;

				a {
					display:block;
					font-size:26px;
					border:solid 2px white;
					border-radius:30px;
					width:50px;
					height:50px;
					padding:10px 0 0 14px;
					i {
					}
				}

				a.instagram {
					padding:9px 0 0 11px;
				}

				a.linkedin {
					padding:9px 0 0 12px;
				}
				
				a:hover {
					border:solid 2px #eee798;
				}
			}
		}
	}
	
}