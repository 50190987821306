/* PAGE HEADER */
.page-header {
	
	h1.title-page {
		font-size:50px;
		padding:40px 0;
		text-align:center;
		font-weight:600;
	}

	.chapeau {

		padding:40px 20%;

		h2 {
			font-size:22px;
			margin-bottom:0;
			line-height:30px;
			font-weight:500;
		}
	}
	
	.block-beige {
		p {
			padding-right:20%;
			padding-left:20px;
		}
	}
	
	.block-vert-3 {
		
		p {
			text-align:center;
			padding-right:20px;
			padding-left:20px;
		}
		p.font-2 {
			font-size:45px;
			line-height:2.5rem;
		}
		p.auteur {
			margin-top:20px;
		}
		p.auteur:before {
			content:'';
			display:block;
			width:50px;
			margin:0 auto;
			border-top:solid 2px $beige-fonce;
			padding-bottom:20px;
		}
		
	}
	
}

.serie_carres .block-img {
	background-size:cover !important;
	height:auto !important
}

/* BLOCKS */
.block-vert {
	background-color:$vert;
}
.block-vert-2 {
	background-color:$vert-2;
	color:white;
	
	a {
		color:white;

		i {
			color:white !important;
		}
	}
}
.block-vert-3 {
	background-color:$vert-3;
	color:white;
	
	a {
		color:white;

		i {
			color:white !important;
		}
	}
}
.block-vert-4 {
	background-color:$vert-4;
	color:white;
	
	a {
		color:white;

		i {
			color:white !important;
		}
	}
}
.block-vert-fonce {
	background-color:$vert-fonce;
	color:white;
	
	a {
		color:white;

		i {
			color:white !important;
		}
	}
}
.block-vert-fonce-2 {
	background-color:$vert-fonce-2;
	color:#f9f4eb !important;
	
	a {
		color:#f9f4eb !important;

		i {
			color:#f9f4eb !important;
		}
	}
	a:hover {
		color:#eee798 !important;

		i {
			color:#eee798 !important;
		}
	}
}
.block-orange {
	background-color:$orange;
	color:white;
	
	a {
		color:white;

		i {
			color:white !important;
		}
	}
}
.block-beige {
	background-color:$beige;
}
.block-beige-fonce {
	background-color:$beige-fonce;
}
.block-violet {
	background-color:$violet;
	color:white;
	
	a {
		color:white;

		i {
			color:white !important;
		}
	}
}

.h-500 {
	height:500px;
}
.h-400 {
	height:420px;
}
.h-300 {
	height:326px;
}

.v-align {
	
	display: table;
	
	> div {
		z-index: 1;
		display: table-cell;
		vertical-align: middle;
		height: 100%;
	}
}

/* HOME */

/* slider */
.carousel,
#carousel {
	height:700px;
	
	.carousel-inner {
		height:715px;
		.carousel-item {
			height:715px;
			position:relative;
			
			.carousel-caption {
				position:absolute;
				bottom:125px;
				
				h2 {
					color:white;
					font-size:50px;
					font-weight:700;
					margin-bottom:40px;
				}
				
				a {
					padding:10px 20px;
					border-radius:50px;
					background-color:white;
					color:black;
					font-weight:600;
					text-decoration:none;
					
					i {
						color:$vert;
					}
					
				}
			}
		}
		
	}
	
	.carousel-indicators {
		button {
			width: 10px;
			height: 10px;
			border-radius:10px;
			border:none;
			background:white;
		}
	}
}

.carousel {
	height:605px;
	.carousel-inner {
		height:565px;
		overflow:hidden;
		.carousel-item {
			height:565px;
		}
	}
}
.home .carousel,
.home #carousel {
	height:480px;
	.carousel-inner {
		height:495px;
		.carousel-item {
			height:495px;
			position:relative;
		}
	}
}

.block-texte {
	
	text-align:center;
	padding-left:20%;
	padding-right:20%;
	
	h1 {
		font-size:40px;
		line-height:2.8rem;
		margin-bottom:20px;
	}
	
	h2 {
		font-size:24px;
		margin-bottom:20px;
	}
	h2:after {
		display:block;
		content:'';
		width:30px;
		margin:0 auto;
		margin-top:20px;
		border-bottom:solid 2px $main;
	}
	h3 {
		font-size:18px;
		font-style:italic;
		font-weight:$default-font-weight;
		line-height:26px;
	}
	a {
		margin-top:25px;
		font-weight:600;
		text-decoration:none;
		display:block;

		i {
			color:$vert;
		}

	}
	
	a.spec {
		margin-top:8px;
		font-weight:500;
		font-size:19px;
	}
	a.spec:hover,
	a.spec:hover i {
		color:$beige-fonce !important;
	}
	
	p {
		margin-bottom:7px;
		font-size:17px;
	}
	
}

.block-texte.font-2 {
	
	h1 {
		margin-bottom:0px;
	}
}

.block-texte.parle-de-nous {
	font-weight:700;
	padding-left:50px;
	padding-right:50px;
	font-size:24px;
	line-height:28px;
	
	div {
		background-image:url('img/picto_Verbatim.svg');
		background-size:45%;
		background-position: 35% 45%;
	}
}

.block-texte.padd-2 {
	
	padding-left:10%;
	padding-right:10%;
	
}

.title {
	font-size:50px;
	padding:40px 0;
	font-weight:600;
}
.title.actus {
	padding:60px 0;
	margin:0;
}
.rea .title,
body:not(.home) .title.actus {
	font-size:30px;
}

.actus {
	
	padding-top:0px;
	
	> .row > div {
		padding-left:0 !important;
		padding-right:0 !important;
	}
}
.actus-page {
	margin-bottom:55px;
	margin-top: 0px;
}
body.single-post .actus-page {
	margin-bottom:0;
}

body.page-id-194 .actus-page > div:before,
body.page-id-79 .actus-page > div:before,
body.page-id-80 .actus-page > div:before {
	border-top:solid 1px #ccc;
	content:'';
	width:100%;
	position:relative;
	display: block;
}
body.page-id-194 .actus-page,
body.page-id-79 .actus-page,
body.page-id-80 .actus-page {
	margin-top: 70px;
}


.realisation {
	
	height:420px;
	position:relative;
	
	a:hover {
		color:$vert-hover !important;
	}
	
	> div {
		position:absolute;
		bottom:0;
		left:40px;
		width:calc(100% - 80px);
		background-color:white;
		padding:20px;
		
		h3 {
			font-size:16px;
		}
		h2 {
			font-size:22px;
			font-weight:700;
			margin-bottom:0;
		}
		h4 {
			font-size:14px;
			margin-bottom:0;
			font-weight:600;
		}
		
	}
}


.realisations,
.actu {

	position:relative;
	
	a:hover {
		color:$vert-hover !important;
	}
	
	> div {
		
		height:420px;
		
		> div.texte {
			position:absolute;
			bottom:-55px;
			left:40px;
			width:calc(100% - 80px);
			background-color:$beige;
			padding:20px;
			height:110px;

			h2 {
				font-size:18px;
				font-weight:700;
				margin-bottom:5px;
			}
			p {
				font-size:16px;
				margin-bottom:0;
			}

		}
	}
}

.single-realisation .realisations {
	margin-bottom:95px;
}
.realisations {
	
	margin-bottom:55px;
	
	> div.lien {
		padding: 20px 0 10px; 
		height:20px;
	}
}
.actus-page {
	
	div.lien {
		padding: 60px 0 25px;
		height: 20px;
	}
}

.realisations-list,
.realisations {
	
	div.texte {
		background-color:white !important;
		bottom:0px !important;
		padding:15px 20px !important;
		min-height:118px;
		
		h2 {
			font-size:24px;
		}
		h3 {
			font-weight:normal;
			font-size:16px;
		}
		h4 {
			font-size:16px;
			margin-bottom:0;
		}
		
	}
}

h2.mini {
	font-size:35px;
	font-weight:600;
}

/* GREEN TEAM */

#green-team {
	
	h2 {
		font-size:35px;
		font-weight:600;
	}
	
	p {
		padding:20px;
	}
	
}

/* REJOINDRE */

#rejoindre {
	
	h2 {
		font-size:35px;
		font-weight:600;
	}
	
	a {
		display:inline-block;
		margin-top:100px;
		border:solid 1px $vert;
		border-radius:30px;
		padding:4px 20px;
		font-weight:600;
		margin-bottom:15px;
		
		i {
			color:$vert;
		}
		
	}
	
	a:hover {
		color:white !important;
		background-color:$vert-4;
		
		i {
			color:white;
		}
	}
	
}

#presence {
	border-top:solid 1px #ccc;
	border-bottom:solid 1px #ccc;
	margin-top:80px;
	
	> div {
		
		padding-top:60px;
		padding-bottom:60px;
	}
	
	h2 {
		font-size:35px;
		margin-bottom:20px;
		font-weight:600;
	}
	
}

.item,
.block-texte,
.engagement,
#rejoindre,
#presence {
	ul {
		margin:0;
		padding-left:10px;
		list-style:none;
		
		li {
			position:relative;
			padding-left: 10px;
			font-size:17px;
		}
		
		li::before {
			content: "•";
			color: $vert;
			font-size:30px;
			top:-2px;
			left:-12px;
			position:absolute;
			font-weight:700;
		}
	}
}
#presence {
	ul {
		margin:0;
		padding-left:10px;
		list-style:none;
		li {
			font-weight:700;			
		}
	}
}
.block-texte {
	ul {
		padding-left:30px;
	}
}


/* VISION */

#solutions,
#valeurs {
	
	.title {
		font-size:30px !important;
	}
	
	.visuel {
		
		padding-bottom:30px;
		
		> div  {
			text-align:center;
			color:white;
			font-weight:600;
			font-size:40px;
			line-height:2.5rem;
			height:300px;
			padding-left:40px;
			padding-right:40px;
			width: 100%;
		}
		
		ul {
			padding:30px 20px !important;
		}
		
		a {
			padding-left:30px;
			padding-right:30px;
			font-weight:600;
			
			i {
				color:$vert;
			}
		}
	}
	
}

/* SAVOIR FAIRE */

.nav-item-interne {
	
	padding:0;
	text-align:center;
	padding:50px 0;
	display: flex;
	flex-direction: row;
	list-style: none;
	border-bottom:solid 1px #ccc;
	
	li {
		border-left:solid 1px #ccc;
		text-align:center;
		display: table;
		
		a {
			padding: 14px 40px;
			z-index: 1;
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
		
		a:hover {
			color:$vert;
		}
	}
	li:first-child {
		border:none;
	}
	
}
.nav-item-interne.engagement {
	li {
		max-width:inherit;
	}
}

.items {
	
	.item {
		
		border-top:solid 1px #ccc;
		padding-top:40px;
		margin-top:50px;
		
		.back-nav-interne {
			color:$vert-4;
			float:right;
			i {
				font-size:18px;
			}
		}
		
		h2 {
			font-size:30px;
			margin-bottom:25px;
		}
		h3 {
			font-size:24px;
			margin-bottom:15px;
		}
		
		img {
			margin:20px 0 30px;
		}
		
		.enavant {
			padding:30px 45px;
			text-align:center;
			font-size:35px;
			line-height:2.3rem;
		}
		
	}
	
	.item:first-child {
		margin-top:0px;
		border:none;
	}
	
}

.engagements {
	
	.nav-item-interne {
		li {
			width:50%;
			cursor:pointer;
			border:none;
			padding: 20px 40px;
			
			i {
				color:$vert;
			}
		}
		li.active {
			background-color:$grey;
			color:white;
			text-decoration:underline;
		}
	}
	
	h2 {
		font-weight:700;
		font-size:20px;
	}
	
	.liste-engagement {
		padding-top:20px;
	}
	
	.engagement {
		
		img.icon {
			width: auto !important;
			height: 100px;
		}
		
		h2 {
			font-size:18px;
			margin-top:14px;
			border-bottom:solid 1px #ccc;
			padding-bottom:12px;
			margin-bottom:12px;
			font-weight:700;
		}
		
		ul li {
			margin-bottom:20px;
		}
		
	}
	
	.liste-engagement {
		
		.engagement:nth-child(even) {
			padding-right:30px;
			border-right: dashed 1px #ccc;
		}
		.engagement:nth-child(odd) {
			padding-left:30px;
			
		}
	}

}

/* PRODUITS */

.page-header-2 {
	
	padding:40px 70px;
	
	h1 {
		font-size:40px;
		font-weight:600;
	}
	h2 {
		margin-top:30px;
		font-size:18px;
		font-weight:normal;
	}
	
	ul.inline {
		
		margin-top:15px;
		padding-left:0;
		
		li {
			
			display:inline-block;
			margin-right:16px;
			margin-top:10px;
			font-weight:600;
			
			i {
				color:$vert;
			}
			
		}
	}
	
	.filtres {
	
		border-top: solid 2px white;
		padding-top:15px;
		margin-top:15px;
		padding-left:0;
		
	}
	
}

.page-header-2.single-produit {
	
	padding: 30px;
	
	.texte .partage,
	.partage {
		
		float:right;
		text-align:center;
		
		span {
			display:block;
			border-bottom:solid 1px black;
			font-size:14px;
		}
		
		.ssba {
		
			a {
				border-left:1px solid black;
				display:inline-block;
				padding:0 2px;
			}
			a:first-child {
				border:none;
			}
			img {
				width:21px !important;
			}
		
		}
		
		ul {
			
			padding:0;
			
			li {
				display:inline-block;
				text-align:center;
				
				a {
					padding:0 5px;
					border-left:1px solid black;
					
					i {
						color:$main;
					}
				}
				
			}
			li::before {
				display:none;
			}
			
			li:first-child a {
				border:none;
			}
			
		}
		
	}
	
	.texte {

		margin-top:30px;
		
		h1 {
			font-size:30px !important;
			margin-bottom:5px !important;
			width:80%;
		}
		h2 {
			font-size:20px !important;
			font-weight:600;
			margin-bottom:20px;
		}
		a {

			font-weight:600;

			i {
				color:$vert;
			}
		}
	}

}


.product-list {
	
	/*margin-bottom:40px;*/
	
	.product {
		height:360px;
		position:relative;
		background-position:center 0;
		background-size:contain  !important;
		
		a {
			display:block;
			height:100%;
			cursor:pointer;
		}
		
		.texte {
			position:absolute;
			bottom:0;
			left:40px;
			width:calc(100% - 80px);
			background-color:$beige;
			padding:20px;
			
			h2 {
				font-size:24px;
				margin-bottom:0;
			}
			h3 {
				font-size:16px;
			}
		}
		
	}

}


/* SINGLE REALISATION */
ul.categorie {
	list-style:none;
	padding:0;
	
	li {
		
		font-weight:600;
		font-size:15px;
		margin-bottom:5px;
		
		i {
			color:$vert;
		}
		
	}
	
}
.block-carousel {
	padding-top:30px;
	position:relative;
	padding-bottom: 25px;
	
	h2 {
		margin-bottom:30px;
	}
	.carousel-control-prev {
		left:-135px;
	}
	.carousel-control-next {
		right:-135px;
	}
	
}
#carousel, 
.block-carousel {
	
	
	.carousel-control-prev-icon,
	.carousel-control-next-icon {
		border:solid 1px $grey;
		border-radius:30px;
		padding:10px;
		display:block;
		background-size: 60% !important;
	}
	
	.carousel-control-prev img,
	.carousel-control-next img {
		width:46px !important;
	}
	.carousel-control-prev img {
		transform: rotate(180deg);
	}
}


.home #carousel {
	.carousel-control-prev {
		left:-40px;
	}
	.carousel-control-next {
		right:-40px;
	}
}




/* REALISATION */

.filtres {
	
	
	.filtre {
		
		position:relative;
		border:1px solid $beige-fonce-2;
		
		.dropdown {
			background-color:white;
			padding:6px;
			text-align:center;
			cursor:pointer;
		}
			
		.dropdown-choices {
			display:none;
			position:absolute;
			z-index:9;
			top:30px;
			left:-1px;
			padding:15px 25px;
			background-color:white;
			border:1px solid $beige-fonce-2;
			border-top:none;
			width:calc(100% + 2px);
			
			.dropdown-choice {
				
				margin-bottom:6px;
				
			}
			
		}
		
	}
	
	input[type="checkbox"] {
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		box-shadow: none;
		border:none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		-o-box-shadow: none;
		outline:1px solid $beige-fonce-2;
		background-color:white;
		padding:6px;
		text-align:center;
		cursor:pointer;
		position: relative;
		border-radius: 3px;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		-o-border-radius: 3px;
	}
	input[type="checkbox"]:checked::after {
	  position: absolute;
	  top: -9px;
	  left: 2px;
	  content: '✔';
	  font-size: 16px;
	  color: $beige-fonce-2;
	  font-weight: bold;
	}
	
	button {
	  border: none !important;
	  width: auto;
	  background-color: transparent;
	  padding: 4px;
	  position: relative;
	  margin-top:4px;
		font-weight:500;
		
		i {
			color:$vert;
		}
	}
	button:hover {
	  color:$vert;
	}
	
}

.realisations-list {

	/*margin-bottom:40px;*/
	
	.realisation {
		height:360px;
		position:relative;
		
		a {
			display:block;
			height:100%;
		}
		
		.texte {
			position:absolute;
			bottom:0;
			left:40px;
			width:calc(100% - 80px);
			background-color:white;
			padding:20px;
			
			h2 {
				font-size:24px;
				margin-bottom:0;
				font-weight:600;
			}
			h3 {
				font-size:16px;
			}
		}
		
	}

}


/* CONTACT */

.contact {
	
	margin:40px auto;
	
}

form {
		
	label {
		width:100%;
		margin-top:20px;
		margin-bottom:5px;
	}
	input, textarea {
		border:solid 1px $beige-fonce-2;
		padding:4px;
		width:100%;
	}
	.wpcf7-submit,
	button.submit {
		border:none !important;
		width:auto;
		background-color:transparent;
		padding:4px;
		margin-top:26px;
		position:relative;
		font-weight:500;

		i {
			color:$vert;
		}

	}

	.wpcf7-submit:hover,
	button.submit:hover {

		color:$vert;

	}

}
