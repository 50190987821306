@charset "UTF-8";
@import url(../node_modules/bootstrap/dist/css/bootstrap.min.css);
@import url(../node_modules/@fancyapps/ui/dist/fancybox.css);
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }
  a:hover {
    color: #0a58ca; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1280px; } }

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 4.16667%; }

.col-2 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-3 {
  flex: 0 0 auto;
  width: 12.5%; }

.col-4 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-5 {
  flex: 0 0 auto;
  width: 20.83333%; }

.col-6 {
  flex: 0 0 auto;
  width: 25%; }

.col-7 {
  flex: 0 0 auto;
  width: 29.16667%; }

.col-8 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-9 {
  flex: 0 0 auto;
  width: 37.5%; }

.col-10 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-11 {
  flex: 0 0 auto;
  width: 45.83333%; }

.col-12 {
  flex: 0 0 auto;
  width: 50%; }

.col-13 {
  flex: 0 0 auto;
  width: 54.16667%; }

.col-14 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-15 {
  flex: 0 0 auto;
  width: 62.5%; }

.col-16 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-17 {
  flex: 0 0 auto;
  width: 70.83333%; }

.col-18 {
  flex: 0 0 auto;
  width: 75%; }

.col-19 {
  flex: 0 0 auto;
  width: 79.16667%; }

.col-20 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-21 {
  flex: 0 0 auto;
  width: 87.5%; }

.col-22 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-23 {
  flex: 0 0 auto;
  width: 95.83333%; }

.col-24 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 4.16667%; }

.offset-2 {
  margin-left: 8.33333%; }

.offset-3 {
  margin-left: 12.5%; }

.offset-4 {
  margin-left: 16.66667%; }

.offset-5 {
  margin-left: 20.83333%; }

.offset-6 {
  margin-left: 25%; }

.offset-7 {
  margin-left: 29.16667%; }

.offset-8 {
  margin-left: 33.33333%; }

.offset-9 {
  margin-left: 37.5%; }

.offset-10 {
  margin-left: 41.66667%; }

.offset-11 {
  margin-left: 45.83333%; }

.offset-12 {
  margin-left: 50%; }

.offset-13 {
  margin-left: 54.16667%; }

.offset-14 {
  margin-left: 58.33333%; }

.offset-15 {
  margin-left: 62.5%; }

.offset-16 {
  margin-left: 66.66667%; }

.offset-17 {
  margin-left: 70.83333%; }

.offset-18 {
  margin-left: 75%; }

.offset-19 {
  margin-left: 79.16667%; }

.offset-20 {
  margin-left: 83.33333%; }

.offset-21 {
  margin-left: 87.5%; }

.offset-22 {
  margin-left: 91.66667%; }

.offset-23 {
  margin-left: 95.83333%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-sm-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-sm-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-sm-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-sm-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-sm-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-sm-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 4.16667%; }
  .offset-sm-2 {
    margin-left: 8.33333%; }
  .offset-sm-3 {
    margin-left: 12.5%; }
  .offset-sm-4 {
    margin-left: 16.66667%; }
  .offset-sm-5 {
    margin-left: 20.83333%; }
  .offset-sm-6 {
    margin-left: 25%; }
  .offset-sm-7 {
    margin-left: 29.16667%; }
  .offset-sm-8 {
    margin-left: 33.33333%; }
  .offset-sm-9 {
    margin-left: 37.5%; }
  .offset-sm-10 {
    margin-left: 41.66667%; }
  .offset-sm-11 {
    margin-left: 45.83333%; }
  .offset-sm-12 {
    margin-left: 50%; }
  .offset-sm-13 {
    margin-left: 54.16667%; }
  .offset-sm-14 {
    margin-left: 58.33333%; }
  .offset-sm-15 {
    margin-left: 62.5%; }
  .offset-sm-16 {
    margin-left: 66.66667%; }
  .offset-sm-17 {
    margin-left: 70.83333%; }
  .offset-sm-18 {
    margin-left: 75%; }
  .offset-sm-19 {
    margin-left: 79.16667%; }
  .offset-sm-20 {
    margin-left: 83.33333%; }
  .offset-sm-21 {
    margin-left: 87.5%; }
  .offset-sm-22 {
    margin-left: 91.66667%; }
  .offset-sm-23 {
    margin-left: 95.83333%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-md-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-md-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-md-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-md-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-md-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-md-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 4.16667%; }
  .offset-md-2 {
    margin-left: 8.33333%; }
  .offset-md-3 {
    margin-left: 12.5%; }
  .offset-md-4 {
    margin-left: 16.66667%; }
  .offset-md-5 {
    margin-left: 20.83333%; }
  .offset-md-6 {
    margin-left: 25%; }
  .offset-md-7 {
    margin-left: 29.16667%; }
  .offset-md-8 {
    margin-left: 33.33333%; }
  .offset-md-9 {
    margin-left: 37.5%; }
  .offset-md-10 {
    margin-left: 41.66667%; }
  .offset-md-11 {
    margin-left: 45.83333%; }
  .offset-md-12 {
    margin-left: 50%; }
  .offset-md-13 {
    margin-left: 54.16667%; }
  .offset-md-14 {
    margin-left: 58.33333%; }
  .offset-md-15 {
    margin-left: 62.5%; }
  .offset-md-16 {
    margin-left: 66.66667%; }
  .offset-md-17 {
    margin-left: 70.83333%; }
  .offset-md-18 {
    margin-left: 75%; }
  .offset-md-19 {
    margin-left: 79.16667%; }
  .offset-md-20 {
    margin-left: 83.33333%; }
  .offset-md-21 {
    margin-left: 87.5%; }
  .offset-md-22 {
    margin-left: 91.66667%; }
  .offset-md-23 {
    margin-left: 95.83333%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-lg-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-lg-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-lg-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-lg-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-lg-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-lg-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 4.16667%; }
  .offset-lg-2 {
    margin-left: 8.33333%; }
  .offset-lg-3 {
    margin-left: 12.5%; }
  .offset-lg-4 {
    margin-left: 16.66667%; }
  .offset-lg-5 {
    margin-left: 20.83333%; }
  .offset-lg-6 {
    margin-left: 25%; }
  .offset-lg-7 {
    margin-left: 29.16667%; }
  .offset-lg-8 {
    margin-left: 33.33333%; }
  .offset-lg-9 {
    margin-left: 37.5%; }
  .offset-lg-10 {
    margin-left: 41.66667%; }
  .offset-lg-11 {
    margin-left: 45.83333%; }
  .offset-lg-12 {
    margin-left: 50%; }
  .offset-lg-13 {
    margin-left: 54.16667%; }
  .offset-lg-14 {
    margin-left: 58.33333%; }
  .offset-lg-15 {
    margin-left: 62.5%; }
  .offset-lg-16 {
    margin-left: 66.66667%; }
  .offset-lg-17 {
    margin-left: 70.83333%; }
  .offset-lg-18 {
    margin-left: 75%; }
  .offset-lg-19 {
    margin-left: 79.16667%; }
  .offset-lg-20 {
    margin-left: 83.33333%; }
  .offset-lg-21 {
    margin-left: 87.5%; }
  .offset-lg-22 {
    margin-left: 91.66667%; }
  .offset-lg-23 {
    margin-left: 95.83333%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-xl-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-xl-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-xl-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-xl-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-xl-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-xl-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 4.16667%; }
  .offset-xl-2 {
    margin-left: 8.33333%; }
  .offset-xl-3 {
    margin-left: 12.5%; }
  .offset-xl-4 {
    margin-left: 16.66667%; }
  .offset-xl-5 {
    margin-left: 20.83333%; }
  .offset-xl-6 {
    margin-left: 25%; }
  .offset-xl-7 {
    margin-left: 29.16667%; }
  .offset-xl-8 {
    margin-left: 33.33333%; }
  .offset-xl-9 {
    margin-left: 37.5%; }
  .offset-xl-10 {
    margin-left: 41.66667%; }
  .offset-xl-11 {
    margin-left: 45.83333%; }
  .offset-xl-12 {
    margin-left: 50%; }
  .offset-xl-13 {
    margin-left: 54.16667%; }
  .offset-xl-14 {
    margin-left: 58.33333%; }
  .offset-xl-15 {
    margin-left: 62.5%; }
  .offset-xl-16 {
    margin-left: 66.66667%; }
  .offset-xl-17 {
    margin-left: 70.83333%; }
  .offset-xl-18 {
    margin-left: 75%; }
  .offset-xl-19 {
    margin-left: 79.16667%; }
  .offset-xl-20 {
    margin-left: 83.33333%; }
  .offset-xl-21 {
    margin-left: 87.5%; }
  .offset-xl-22 {
    margin-left: 91.66667%; }
  .offset-xl-23 {
    margin-left: 95.83333%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 4.16667%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 12.5%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 20.83333%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 29.16667%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 37.5%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 45.83333%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-13 {
    flex: 0 0 auto;
    width: 54.16667%; }
  .col-xxl-14 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-15 {
    flex: 0 0 auto;
    width: 62.5%; }
  .col-xxl-16 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-17 {
    flex: 0 0 auto;
    width: 70.83333%; }
  .col-xxl-18 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-19 {
    flex: 0 0 auto;
    width: 79.16667%; }
  .col-xxl-20 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-21 {
    flex: 0 0 auto;
    width: 87.5%; }
  .col-xxl-22 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-23 {
    flex: 0 0 auto;
    width: 95.83333%; }
  .col-xxl-24 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 4.16667%; }
  .offset-xxl-2 {
    margin-left: 8.33333%; }
  .offset-xxl-3 {
    margin-left: 12.5%; }
  .offset-xxl-4 {
    margin-left: 16.66667%; }
  .offset-xxl-5 {
    margin-left: 20.83333%; }
  .offset-xxl-6 {
    margin-left: 25%; }
  .offset-xxl-7 {
    margin-left: 29.16667%; }
  .offset-xxl-8 {
    margin-left: 33.33333%; }
  .offset-xxl-9 {
    margin-left: 37.5%; }
  .offset-xxl-10 {
    margin-left: 41.66667%; }
  .offset-xxl-11 {
    margin-left: 45.83333%; }
  .offset-xxl-12 {
    margin-left: 50%; }
  .offset-xxl-13 {
    margin-left: 54.16667%; }
  .offset-xxl-14 {
    margin-left: 58.33333%; }
  .offset-xxl-15 {
    margin-left: 62.5%; }
  .offset-xxl-16 {
    margin-left: 66.66667%; }
  .offset-xxl-17 {
    margin-left: 70.83333%; }
  .offset-xxl-18 {
    margin-left: 75%; }
  .offset-xxl-19 {
    margin-left: 79.16667%; }
  .offset-xxl-20 {
    margin-left: 83.33333%; }
  .offset-xxl-21 {
    margin-left: 87.5%; }
  .offset-xxl-22 {
    margin-left: 91.66667%; }
  .offset-xxl-23 {
    margin-left: 95.83333%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  scroll-behavior: smooth; }

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  outline: none; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.vert {
  color: #7fbb27; }

.vert-2 {
  color: #719442; }

.vert-3 {
  color: #92a131; }

.vert-4 {
  color: #8eb844; }

.vert-fonce {
  color: #325150; }

.violet {
  color: #402331; }

.beige {
  color: #faf4ea; }

.beige-fonce {
  color: #efe88d; }

.orange {
  color: #c14d26; }

.grey {
  color: #666; }

body {
  color: #313a2e;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  line-height: 1.6rem;
  font-weight: 500;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow-x: hidden;
  scroll-behavior: smooth; }

.font-2 {
  font-family: 'Professor', sans-serif; }

a, a:hover {
  color: #313a2e;
  text-decoration: none; }

a:hover {
  color: #8eb844 !important; }

.site-content {
  padding-top: 108px;
  padding-bottom: 40px; }
  .site-content img {
    width: 100% !important;
    max-width: 100% !important; }

img {
  max-width: 100% !important; }

ul.texte {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important; }
  ul.texte li {
    margin-left: 30px;
    padding-left: 5px;
    position: relative;
    margin-bottom: 10px; }
  ul.texte li::before {
    position: absolute;
    left: -18px;
    top: -4px;
    font-size: 46px;
    content: "•";
    color: #7fbb27; }

.gl-0 {
  padding-left: 0 !important; }

.gr-0 {
  padding-right: 0 !important; }

.page-template-contact .menu-container,
.page-template-default .menu-container {
  border-bottom: solid 1px #ccc; }

.menu-container {
  position: fixed;
  z-index: 999;
  margin: 0 auto;
  width: 100%;
  background-color: white; }

.navbar {
  /*display:block;*/
  /*.navbar-brand {
		float:left;
		margin-top:20px;
	}*/ }
  .navbar .navbar-nav {
    margin-left: 10px;
    /*margin-top:40px;*/
    /*.nav-link.show {
			height:190px;
		}*/
    /*.dropdown-menu[data-bs-popper] {
			top: 90px !important;
			margin-top:0;
		}*/ }
    .navbar .navbar-nav .nav-link {
      color: #313a2e;
      font-weight: 500;
      /*height:74px;*/ }
    .navbar .navbar-nav .current-page-parent .nav-link,
    .navbar .navbar-nav .current_page_item:not(.btn-contact) .nav-link,
    .navbar .navbar-nav li:not(.btn-contact) .nav-link:hover {
      color: #8eb844 !important;
      /*text-decoration-color:$vert-4 !important;
			text-decoration:underline !important;
			text-decoration-thickness:2px !important;*/ }
    .navbar .navbar-nav .btn-contact {
      margin-left: 20px; }
      .navbar .navbar-nav .btn-contact a {
        height: inherit;
        color: #719442 !important;
        border: solid 1px #8eb844;
        border-radius: 20px;
        padding: 8px 20px !important; }
      .navbar .navbar-nav .btn-contact a:hover {
        color: white !important;
        background-color: #8eb844; }
    .navbar .navbar-nav .dropdown-toggle:after {
      content: '' !important;
      width: 20px;
      height: 20px;
      background: url("img/Chevron_Bas.svg") no-repeat;
      border: none;
      position: absolute;
      top: 14px; }
    .navbar .navbar-nav .dropdown-toggle i {
      color: #7fbb27; }
    .navbar .navbar-nav .dropdown-menu {
      border-top: solid 1px #ccc !important;
      position: fixed;
      top: 106px;
      left: 0;
      width: 100%;
      border-radius: 0;
      border: none;
      padding: 20px;
      transition: 0.2s;
      opacity: 0 !important;
      visibility: hidden;
      display: block; }
      .navbar .navbar-nav .dropdown-menu li {
        display: inline-block;
        width: 49%; }
        .navbar .navbar-nav .dropdown-menu li a, .navbar .navbar-nav .dropdown-menu li a:hover {
          background-color: transparent;
          font-size: 17px;
          font-weight: 500;
          padding: 0 0; }
      .navbar .navbar-nav .dropdown-menu li.return {
        display: block; }
    .navbar .navbar-nav .dropdown-menu.show {
      opacity: 1 !important;
      visibility: visible; }
    .navbar .navbar-nav .dropdown {
      padding-right: 15px; }
    .navbar .navbar-nav .dropdown-item.active, .navbar .navbar-nav .dropdown-item:active {
      color: #313a2e; }
  .navbar .navbar-toggler {
    position: absolute;
    right: 10px;
    top: 10px; }

.navbar.d-lg-block {
  display: flex !important; }

.lien_tous {
  font-size: 16px;
  text-decoration: none; }
  .lien_tous i {
    width: 36px;
    height: 36px;
    margin-left: 6px;
    background-image: url("img/Bnt_Vert_Off.svg");
    display: inline-block;
    top: 12px;
    position: relative; }
  .lien_tous i.beige-fonce {
    color: #e6bb77;
    background-image: url("img/Bnt_Beige_Off.svg"); }

.lien_tous:hover {
  font-size: 16px;
  text-decoration: none; }
  .lien_tous:hover i {
    background-image: url("img/Bnt_Vert_On.svg"); }
  .lien_tous:hover i.beige-fonce {
    background-image: url("img/Bnt_Beige_On.svg"); }

.lien_tous.beige-fonce-2 {
  color: #313a2e !important; }

.lien_tous.beige-fonce-2:hover {
  color: #e6bb77 !important; }

.see_more {
  font-size: 16px;
  text-decoration: none; }
  .see_more i {
    color: #7fbb27;
    border: solid 2px #7fbb27;
    border-radius: 30px;
    width: 28px;
    height: 28px;
    font-size: 16px;
    padding: 4px 4px 4px 5px;
    margin-left: 6px; }
  .see_more i.beige-fonce {
    color: #e6bb77;
    border: solid 2px #e6bb77; }

footer .cta-contact {
  height: 160px;
  background-color: #faf4ea;
  font-size: 28px;
  font-weight: 700;
  line-height: 2.5rem; }
  footer .cta-contact .email,
  footer .cta-contact .tel {
    color: #313a2e;
    display: inline-block; }
    footer .cta-contact .email img,
    footer .cta-contact .tel img {
      width: 50px !important; }
    footer .cta-contact .email a,
    footer .cta-contact .email a:hover,
    footer .cta-contact .tel a,
    footer .cta-contact .tel a:hover {
      color: #313a2e !important; }
  footer .cta-contact .email {
    margin-left: 6px;
    margin-top: 10px; }

footer ul {
  margin: 0;
  padding: 0;
  list-style: none; }

footer #menu-menu-footer-2 {
  margin-top: 27px; }

footer .liens {
  padding-top: 60px;
  padding-bottom: 60px;
  font-size: 16px;
  line-height: 1.8rem; }
  footer .liens .copyright {
    margin-top: 25px;
    display: block;
    font-size: 12px; }
  footer .liens h2, footer .liens .h2 {
    font-size: 16px;
    font-weight: bold; }
  footer .liens a {
    font-size: 16px; }

footer .rs ul {
  text-align: left; }
  footer .rs ul li {
    text-align: left;
    display: inline-block;
    margin-right: 20px; }
    footer .rs ul li a {
      display: block;
      font-size: 26px;
      border: solid 2px white;
      border-radius: 30px;
      width: 50px;
      height: 50px;
      padding: 10px 0 0 14px; }
    footer .rs ul li a.instagram {
      padding: 9px 0 0 11px; }
    footer .rs ul li a.linkedin {
      padding: 9px 0 0 12px; }
    footer .rs ul li a:hover {
      border: solid 2px #eee798; }

/* PAGE HEADER */
.page-header h1.title-page, .page-header .title-page.h1 {
  font-size: 50px;
  padding: 40px 0;
  text-align: center;
  font-weight: 600; }

.page-header .chapeau {
  padding: 40px 20%; }
  .page-header .chapeau h2, .page-header .chapeau .h2 {
    font-size: 22px;
    margin-bottom: 0;
    line-height: 30px;
    font-weight: 500; }

.page-header .block-beige p {
  padding-right: 20%;
  padding-left: 20px; }

.page-header .block-vert-3 p {
  text-align: center;
  padding-right: 20px;
  padding-left: 20px; }

.page-header .block-vert-3 p.font-2 {
  font-size: 45px;
  line-height: 2.5rem; }

.page-header .block-vert-3 p.auteur {
  margin-top: 20px; }

.page-header .block-vert-3 p.auteur:before {
  content: '';
  display: block;
  width: 50px;
  margin: 0 auto;
  border-top: solid 2px #efe88d;
  padding-bottom: 20px; }

.serie_carres .block-img {
  background-size: cover !important;
  height: auto !important; }

/* BLOCKS */
.block-vert {
  background-color: #7fbb27; }

.block-vert-2 {
  background-color: #719442;
  color: white; }
  .block-vert-2 a {
    color: white; }
    .block-vert-2 a i {
      color: white !important; }

.block-vert-3 {
  background-color: #92a131;
  color: white; }
  .block-vert-3 a {
    color: white; }
    .block-vert-3 a i {
      color: white !important; }

.block-vert-4 {
  background-color: #8eb844;
  color: white; }
  .block-vert-4 a {
    color: white; }
    .block-vert-4 a i {
      color: white !important; }

.block-vert-fonce {
  background-color: #325150;
  color: white; }
  .block-vert-fonce a {
    color: white; }
    .block-vert-fonce a i {
      color: white !important; }

.block-vert-fonce-2 {
  background-color: #333a2f;
  color: #f9f4eb !important; }
  .block-vert-fonce-2 a {
    color: #f9f4eb !important; }
    .block-vert-fonce-2 a i {
      color: #f9f4eb !important; }
  .block-vert-fonce-2 a:hover {
    color: #eee798 !important; }
    .block-vert-fonce-2 a:hover i {
      color: #eee798 !important; }

.block-orange {
  background-color: #c14d26;
  color: white; }
  .block-orange a {
    color: white; }
    .block-orange a i {
      color: white !important; }

.block-beige {
  background-color: #faf4ea; }

.block-beige-fonce {
  background-color: #efe88d; }

.block-violet {
  background-color: #402331;
  color: white; }
  .block-violet a {
    color: white; }
    .block-violet a i {
      color: white !important; }

.h-500 {
  height: 500px; }

.h-400 {
  height: 420px; }

.h-300 {
  height: 326px; }

.v-align {
  display: table; }
  .v-align > div {
    z-index: 1;
    display: table-cell;
    vertical-align: middle;
    height: 100%; }

/* HOME */
/* slider */
.carousel,
#carousel {
  height: 700px; }
  .carousel .carousel-inner,
  #carousel .carousel-inner {
    height: 715px; }
    .carousel .carousel-inner .carousel-item,
    #carousel .carousel-inner .carousel-item {
      height: 715px;
      position: relative; }
      .carousel .carousel-inner .carousel-item .carousel-caption,
      #carousel .carousel-inner .carousel-item .carousel-caption {
        position: absolute;
        bottom: 125px; }
        .carousel .carousel-inner .carousel-item .carousel-caption h2, .carousel .carousel-inner .carousel-item .carousel-caption .h2,
        #carousel .carousel-inner .carousel-item .carousel-caption h2,
        #carousel .carousel-inner .carousel-item .carousel-caption .h2 {
          color: white;
          font-size: 50px;
          font-weight: 700;
          margin-bottom: 40px; }
        .carousel .carousel-inner .carousel-item .carousel-caption a,
        #carousel .carousel-inner .carousel-item .carousel-caption a {
          padding: 10px 20px;
          border-radius: 50px;
          background-color: white;
          color: black;
          font-weight: 600;
          text-decoration: none; }
          .carousel .carousel-inner .carousel-item .carousel-caption a i,
          #carousel .carousel-inner .carousel-item .carousel-caption a i {
            color: #7fbb27; }
  .carousel .carousel-indicators button,
  #carousel .carousel-indicators button {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    border: none;
    background: white; }

.carousel {
  height: 605px; }
  .carousel .carousel-inner {
    height: 565px;
    overflow: hidden; }
    .carousel .carousel-inner .carousel-item {
      height: 565px; }

.home .carousel,
.home #carousel {
  height: 480px; }
  .home .carousel .carousel-inner,
  .home #carousel .carousel-inner {
    height: 495px; }
    .home .carousel .carousel-inner .carousel-item,
    .home #carousel .carousel-inner .carousel-item {
      height: 495px;
      position: relative; }

.block-texte {
  text-align: center;
  padding-left: 20%;
  padding-right: 20%; }
  .block-texte h1, .block-texte .h1 {
    font-size: 40px;
    line-height: 2.8rem;
    margin-bottom: 20px; }
  .block-texte h2, .block-texte .h2 {
    font-size: 24px;
    margin-bottom: 20px; }
  .block-texte h2:after, .block-texte .h2:after {
    display: block;
    content: '';
    width: 30px;
    margin: 0 auto;
    margin-top: 20px;
    border-bottom: solid 2px #313a2e; }
  .block-texte h3, .block-texte .h3 {
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 26px; }
  .block-texte a {
    margin-top: 25px;
    font-weight: 600;
    text-decoration: none;
    display: block; }
    .block-texte a i {
      color: #7fbb27; }
  .block-texte a.spec {
    margin-top: 8px;
    font-weight: 500;
    font-size: 19px; }
  .block-texte a.spec:hover,
  .block-texte a.spec:hover i {
    color: #efe88d !important; }
  .block-texte p {
    margin-bottom: 7px;
    font-size: 17px; }

.block-texte.font-2 h1, .block-texte.font-2 .h1 {
  margin-bottom: 0px; }

.block-texte.parle-de-nous {
  font-weight: 700;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 24px;
  line-height: 28px; }
  .block-texte.parle-de-nous div {
    background-image: url("img/picto_Verbatim.svg");
    background-size: 45%;
    background-position: 35% 45%; }

.block-texte.padd-2 {
  padding-left: 10%;
  padding-right: 10%; }

.title {
  font-size: 50px;
  padding: 40px 0;
  font-weight: 600; }

.title.actus {
  padding: 60px 0;
  margin: 0; }

.rea .title,
body:not(.home) .title.actus {
  font-size: 30px; }

.actus {
  padding-top: 0px; }
  .actus > .row > div {
    padding-left: 0 !important;
    padding-right: 0 !important; }

.actus-page {
  margin-bottom: 55px;
  margin-top: 0px; }

body.single-post .actus-page {
  margin-bottom: 0; }

body.page-id-194 .actus-page > div:before,
body.page-id-79 .actus-page > div:before,
body.page-id-80 .actus-page > div:before {
  border-top: solid 1px #ccc;
  content: '';
  width: 100%;
  position: relative;
  display: block; }

body.page-id-194 .actus-page,
body.page-id-79 .actus-page,
body.page-id-80 .actus-page {
  margin-top: 70px; }

.realisation {
  height: 420px;
  position: relative; }
  .realisation a:hover {
    color: #55882e !important; }
  .realisation > div {
    position: absolute;
    bottom: 0;
    left: 40px;
    width: calc(100% - 80px);
    background-color: white;
    padding: 20px; }
    .realisation > div h3, .realisation > div .h3 {
      font-size: 16px; }
    .realisation > div h2, .realisation > div .h2 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 0; }
    .realisation > div h4, .realisation > div .h4 {
      font-size: 14px;
      margin-bottom: 0;
      font-weight: 600; }

.realisations,
.actu {
  position: relative; }
  .realisations a:hover,
  .actu a:hover {
    color: #55882e !important; }
  .realisations > div,
  .actu > div {
    height: 420px; }
    .realisations > div > div.texte,
    .actu > div > div.texte {
      position: absolute;
      bottom: -55px;
      left: 40px;
      width: calc(100% - 80px);
      background-color: #faf4ea;
      padding: 20px;
      height: 110px; }
      .realisations > div > div.texte h2, .realisations > div > div.texte .h2,
      .actu > div > div.texte h2,
      .actu > div > div.texte .h2 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 5px; }
      .realisations > div > div.texte p,
      .actu > div > div.texte p {
        font-size: 16px;
        margin-bottom: 0; }

.single-realisation .realisations {
  margin-bottom: 95px; }

.realisations {
  margin-bottom: 55px; }
  .realisations > div.lien {
    padding: 20px 0 10px;
    height: 20px; }

.actus-page div.lien {
  padding: 60px 0 25px;
  height: 20px; }

.realisations-list div.texte,
.realisations div.texte {
  background-color: white !important;
  bottom: 0px !important;
  padding: 15px 20px !important;
  min-height: 118px; }
  .realisations-list div.texte h2, .realisations-list div.texte .h2,
  .realisations div.texte h2,
  .realisations div.texte .h2 {
    font-size: 24px; }
  .realisations-list div.texte h3, .realisations-list div.texte .h3,
  .realisations div.texte h3,
  .realisations div.texte .h3 {
    font-weight: normal;
    font-size: 16px; }
  .realisations-list div.texte h4, .realisations-list div.texte .h4,
  .realisations div.texte h4,
  .realisations div.texte .h4 {
    font-size: 16px;
    margin-bottom: 0; }

h2.mini, .mini.h2 {
  font-size: 35px;
  font-weight: 600; }

/* GREEN TEAM */
#green-team h2, #green-team .h2 {
  font-size: 35px;
  font-weight: 600; }

#green-team p {
  padding: 20px; }

/* REJOINDRE */
#rejoindre h2, #rejoindre .h2 {
  font-size: 35px;
  font-weight: 600; }

#rejoindre a {
  display: inline-block;
  margin-top: 100px;
  border: solid 1px #7fbb27;
  border-radius: 30px;
  padding: 4px 20px;
  font-weight: 600;
  margin-bottom: 15px; }
  #rejoindre a i {
    color: #7fbb27; }

#rejoindre a:hover {
  color: white !important;
  background-color: #8eb844; }
  #rejoindre a:hover i {
    color: white; }

#presence {
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  margin-top: 80px; }
  #presence > div {
    padding-top: 60px;
    padding-bottom: 60px; }
  #presence h2, #presence .h2 {
    font-size: 35px;
    margin-bottom: 20px;
    font-weight: 600; }

.item ul,
.block-texte ul,
.engagement ul,
#rejoindre ul,
#presence ul {
  margin: 0;
  padding-left: 10px;
  list-style: none; }
  .item ul li,
  .block-texte ul li,
  .engagement ul li,
  #rejoindre ul li,
  #presence ul li {
    position: relative;
    padding-left: 10px;
    font-size: 17px; }
  .item ul li::before,
  .block-texte ul li::before,
  .engagement ul li::before,
  #rejoindre ul li::before,
  #presence ul li::before {
    content: "•";
    color: #7fbb27;
    font-size: 30px;
    top: -2px;
    left: -12px;
    position: absolute;
    font-weight: 700; }

#presence ul {
  margin: 0;
  padding-left: 10px;
  list-style: none; }
  #presence ul li {
    font-weight: 700; }

.block-texte ul {
  padding-left: 30px; }

/* VISION */
#solutions .title,
#valeurs .title {
  font-size: 30px !important; }

#solutions .visuel,
#valeurs .visuel {
  padding-bottom: 30px; }
  #solutions .visuel > div,
  #valeurs .visuel > div {
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: 40px;
    line-height: 2.5rem;
    height: 300px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%; }
  #solutions .visuel ul,
  #valeurs .visuel ul {
    padding: 30px 20px !important; }
  #solutions .visuel a,
  #valeurs .visuel a {
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 600; }
    #solutions .visuel a i,
    #valeurs .visuel a i {
      color: #7fbb27; }

/* SAVOIR FAIRE */
.nav-item-interne {
  padding: 0;
  text-align: center;
  padding: 50px 0;
  display: flex;
  flex-direction: row;
  list-style: none;
  border-bottom: solid 1px #ccc; }
  .nav-item-interne li {
    border-left: solid 1px #ccc;
    text-align: center;
    display: table; }
    .nav-item-interne li a {
      padding: 14px 40px;
      z-index: 1;
      display: table-cell;
      vertical-align: middle;
      height: 100%; }
    .nav-item-interne li a:hover {
      color: #7fbb27; }
  .nav-item-interne li:first-child {
    border: none; }

.nav-item-interne.engagement li {
  max-width: inherit; }

.items .item {
  border-top: solid 1px #ccc;
  padding-top: 40px;
  margin-top: 50px; }
  .items .item .back-nav-interne {
    color: #8eb844;
    float: right; }
    .items .item .back-nav-interne i {
      font-size: 18px; }
  .items .item h2, .items .item .h2 {
    font-size: 30px;
    margin-bottom: 25px; }
  .items .item h3, .items .item .h3 {
    font-size: 24px;
    margin-bottom: 15px; }
  .items .item img {
    margin: 20px 0 30px; }
  .items .item .enavant {
    padding: 30px 45px;
    text-align: center;
    font-size: 35px;
    line-height: 2.3rem; }

.items .item:first-child {
  margin-top: 0px;
  border: none; }

.engagements .nav-item-interne li {
  width: 50%;
  cursor: pointer;
  border: none;
  padding: 20px 40px; }
  .engagements .nav-item-interne li i {
    color: #7fbb27; }

.engagements .nav-item-interne li.active {
  background-color: #666;
  color: white;
  text-decoration: underline; }

.engagements h2, .engagements .h2 {
  font-weight: 700;
  font-size: 20px; }

.engagements .liste-engagement {
  padding-top: 20px; }

.engagements .engagement img.icon {
  width: auto !important;
  height: 100px; }

.engagements .engagement h2, .engagements .engagement .h2 {
  font-size: 18px;
  margin-top: 14px;
  border-bottom: solid 1px #ccc;
  padding-bottom: 12px;
  margin-bottom: 12px;
  font-weight: 700; }

.engagements .engagement ul li {
  margin-bottom: 20px; }

.engagements .liste-engagement .engagement:nth-child(even) {
  padding-right: 30px;
  border-right: dashed 1px #ccc; }

.engagements .liste-engagement .engagement:nth-child(odd) {
  padding-left: 30px; }

/* PRODUITS */
.page-header-2 {
  padding: 40px 70px; }
  .page-header-2 h1, .page-header-2 .h1 {
    font-size: 40px;
    font-weight: 600; }
  .page-header-2 h2, .page-header-2 .h2 {
    margin-top: 30px;
    font-size: 18px;
    font-weight: normal; }
  .page-header-2 ul.inline {
    margin-top: 15px;
    padding-left: 0; }
    .page-header-2 ul.inline li {
      display: inline-block;
      margin-right: 16px;
      margin-top: 10px;
      font-weight: 600; }
      .page-header-2 ul.inline li i {
        color: #7fbb27; }
  .page-header-2 .filtres {
    border-top: solid 2px white;
    padding-top: 15px;
    margin-top: 15px;
    padding-left: 0; }

.page-header-2.single-produit {
  padding: 30px; }
  .page-header-2.single-produit .texte .partage,
  .page-header-2.single-produit .partage {
    float: right;
    text-align: center; }
    .page-header-2.single-produit .texte .partage span,
    .page-header-2.single-produit .partage span {
      display: block;
      border-bottom: solid 1px black;
      font-size: 14px; }
    .page-header-2.single-produit .texte .partage .ssba a,
    .page-header-2.single-produit .partage .ssba a {
      border-left: 1px solid black;
      display: inline-block;
      padding: 0 2px; }
    .page-header-2.single-produit .texte .partage .ssba a:first-child,
    .page-header-2.single-produit .partage .ssba a:first-child {
      border: none; }
    .page-header-2.single-produit .texte .partage .ssba img,
    .page-header-2.single-produit .partage .ssba img {
      width: 21px !important; }
    .page-header-2.single-produit .texte .partage ul,
    .page-header-2.single-produit .partage ul {
      padding: 0; }
      .page-header-2.single-produit .texte .partage ul li,
      .page-header-2.single-produit .partage ul li {
        display: inline-block;
        text-align: center; }
        .page-header-2.single-produit .texte .partage ul li a,
        .page-header-2.single-produit .partage ul li a {
          padding: 0 5px;
          border-left: 1px solid black; }
          .page-header-2.single-produit .texte .partage ul li a i,
          .page-header-2.single-produit .partage ul li a i {
            color: #313a2e; }
      .page-header-2.single-produit .texte .partage ul li::before,
      .page-header-2.single-produit .partage ul li::before {
        display: none; }
      .page-header-2.single-produit .texte .partage ul li:first-child a,
      .page-header-2.single-produit .partage ul li:first-child a {
        border: none; }
  .page-header-2.single-produit .texte {
    margin-top: 30px; }
    .page-header-2.single-produit .texte h1, .page-header-2.single-produit .texte .h1 {
      font-size: 30px !important;
      margin-bottom: 5px !important;
      width: 80%; }
    .page-header-2.single-produit .texte h2, .page-header-2.single-produit .texte .h2 {
      font-size: 20px !important;
      font-weight: 600;
      margin-bottom: 20px; }
    .page-header-2.single-produit .texte a {
      font-weight: 600; }
      .page-header-2.single-produit .texte a i {
        color: #7fbb27; }

.product-list {
  /*margin-bottom:40px;*/ }
  .product-list .product {
    height: 360px;
    position: relative;
    background-position: center 0;
    background-size: contain  !important; }
    .product-list .product a {
      display: block;
      height: 100%;
      cursor: pointer; }
    .product-list .product .texte {
      position: absolute;
      bottom: 0;
      left: 40px;
      width: calc(100% - 80px);
      background-color: #faf4ea;
      padding: 20px; }
      .product-list .product .texte h2, .product-list .product .texte .h2 {
        font-size: 24px;
        margin-bottom: 0; }
      .product-list .product .texte h3, .product-list .product .texte .h3 {
        font-size: 16px; }

/* SINGLE REALISATION */
ul.categorie {
  list-style: none;
  padding: 0; }
  ul.categorie li {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px; }
    ul.categorie li i {
      color: #7fbb27; }

.block-carousel {
  padding-top: 30px;
  position: relative;
  padding-bottom: 25px; }
  .block-carousel h2, .block-carousel .h2 {
    margin-bottom: 30px; }
  .block-carousel .carousel-control-prev {
    left: -135px; }
  .block-carousel .carousel-control-next {
    right: -135px; }

#carousel .carousel-control-prev-icon,
#carousel .carousel-control-next-icon,
.block-carousel .carousel-control-prev-icon,
.block-carousel .carousel-control-next-icon {
  border: solid 1px #666;
  border-radius: 30px;
  padding: 10px;
  display: block;
  background-size: 60% !important; }

#carousel .carousel-control-prev img,
#carousel .carousel-control-next img,
.block-carousel .carousel-control-prev img,
.block-carousel .carousel-control-next img {
  width: 46px !important; }

#carousel .carousel-control-prev img,
.block-carousel .carousel-control-prev img {
  transform: rotate(180deg); }

.home #carousel .carousel-control-prev {
  left: -40px; }

.home #carousel .carousel-control-next {
  right: -40px; }

/* REALISATION */
.filtres .filtre {
  position: relative;
  border: 1px solid #e6bb77; }
  .filtres .filtre .dropdown {
    background-color: white;
    padding: 6px;
    text-align: center;
    cursor: pointer; }
  .filtres .filtre .dropdown-choices {
    display: none;
    position: absolute;
    z-index: 9;
    top: 30px;
    left: -1px;
    padding: 15px 25px;
    background-color: white;
    border: 1px solid #e6bb77;
    border-top: none;
    width: calc(100% + 2px); }
    .filtres .filtre .dropdown-choices .dropdown-choice {
      margin-bottom: 6px; }

.filtres input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  outline: 1px solid #e6bb77;
  background-color: white;
  padding: 6px;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px; }

.filtres input[type="checkbox"]:checked::after {
  position: absolute;
  top: -9px;
  left: 2px;
  content: '✔';
  font-size: 16px;
  color: #e6bb77;
  font-weight: bold; }

.filtres button {
  border: none !important;
  width: auto;
  background-color: transparent;
  padding: 4px;
  position: relative;
  margin-top: 4px;
  font-weight: 500; }
  .filtres button i {
    color: #7fbb27; }

.filtres button:hover {
  color: #7fbb27; }

.realisations-list {
  /*margin-bottom:40px;*/ }
  .realisations-list .realisation {
    height: 360px;
    position: relative; }
    .realisations-list .realisation a {
      display: block;
      height: 100%; }
    .realisations-list .realisation .texte {
      position: absolute;
      bottom: 0;
      left: 40px;
      width: calc(100% - 80px);
      background-color: white;
      padding: 20px; }
      .realisations-list .realisation .texte h2, .realisations-list .realisation .texte .h2 {
        font-size: 24px;
        margin-bottom: 0;
        font-weight: 600; }
      .realisations-list .realisation .texte h3, .realisations-list .realisation .texte .h3 {
        font-size: 16px; }

/* CONTACT */
.contact {
  margin: 40px auto; }

form label {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 5px; }

form input, form textarea {
  border: solid 1px #e6bb77;
  padding: 4px;
  width: 100%; }

form .wpcf7-submit,
form button.submit {
  border: none !important;
  width: auto;
  background-color: transparent;
  padding: 4px;
  margin-top: 26px;
  position: relative;
  font-weight: 500; }
  form .wpcf7-submit i,
  form button.submit i {
    color: #7fbb27; }

form .wpcf7-submit:hover,
form button.submit:hover {
  color: #7fbb27; }

@media (max-width: 1200px) {
  .navbar.d-lg-block {
    display: none !important; }
  .navbar.d-lg-none {
    display: flex !important; }
  .navbar .navbar-brand {
    width: 100%;
    text-align: center; }
  .navbar .nav-link {
    height: auto !important; }
  .navbar .navbar-nav .dropdown-menu li {
    display: block;
    width: 100%; }
  .navbar .btn-contact {
    margin-left: 0 !important;
    width: fit-content !important; }
  .navbar.on:before {
    display: none; }
  .dropdown-menu {
    position: inherit !important;
    top: inherit !important;
    left: inherit !important;
    display: none !important;
    visibility: visible;
    padding: 20px 40px !important; }
    .dropdown-menu * {
      padding: 0; }
    .dropdown-menu li {
      margin: 5px 0; }
  .dropdown-menu.show {
    display: block !important; } }

@media (max-width: 768px) {
  * {
    word-break: break-word; }
  .col-24 {
    padding-left: 15px;
    padding-right: 15px; }
  .items {
    padding-left: 0;
    padding-right: 0; }
  .page-template-default .navbar {
    border: none; }
  .carousel,
  #carousel,
  .carousel-inner,
  .carousel-item {
    height: auto !important; }
  footer .cta-contact {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px; }
  .title {
    padding-left: 20px;
    padding-right: 20px; }
  .title.actus {
    padding-bottom: 0; }
  .lien_tous {
    display: block;
    margin-bottom: 20px; }
  .page-header-2,
  .page-header .chapeau {
    padding: 20px; }
  #rejoindre a {
    margin-top: 10px; }
  #presence {
    margin-top: 20px; }
  .actus-page {
    margin-top: 20px; }
  .block-texte {
    padding: 20px; }
    .block-texte .padd-2 {
      padding-left: 0;
      padding-right: 0; }
  .page-header .block-beige p {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .block-texte.parle-de-nous {
    padding-top: 50px;
    padding-bottom: 50px; }
    .block-texte.parle-de-nous div {
      background-position: 5% 45%; }
  .h-300,
  .h-400,
  .h-500 {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px; }
  .block-img {
    height: 250px; }
  .v-align,
  .v-align > div {
    /*display:block;*/ }
  .v-align {
    padding-top: 0;
    padding-bottom: 0; }
  .nav-item-interne {
    display: block; }
    .nav-item-interne li {
      width: 100% !important;
      display: block !important;
      border: none !important;
      padding: 5px 0 !important; }
      .nav-item-interne li a {
        padding: 0;
        text-align: left; }
  .realisations {
    height: auto; }
  .product-list .product .texte {
    width: 100%;
    left: 0; }
  .filtres .filtre {
    margin-top: 20px; }
    .filtres .filtre .dropdown-choices {
      display: block;
      position: inherit;
      top: 0;
      border-bottom: none; }
  .filtres .col-md-3 input {
    margin-top: 20px; }
  .actus-page > div:before {
    display: none; }
  .actu {
    margin-bottom: 55px; }
  .actu:last-child {
    margin-bottom: 0; }
  .adresse {
    margin-top: 20px; }
  .filtres button {
    color: #313a2e !important;
    font-weight: 500 !important; }
  .engagements .nav-item-interne li {
    text-decoration: underline; } }

/* ANIMATION */
.from_left {
  opacity: 0;
  transition: 0.5s;
  left: -20px; }

.from_bottom {
  opacity: 0;
  transition: 0.5s;
  top: 15px;
  position: relative; }

.appear {
  opacity: 1;
  left: 0;
  top: 0; }

.blockheader {
  position: relative; }

.img-effect {
  width: 300px;
  height: 300px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none; }

.img-effect img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; }
